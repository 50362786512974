import React from "react";
import ColumnText from "../../shared/columnText";

export default function Benefits() {
  return (
    <section id="benefits">
      <ColumnText
        columnStyle="box"
        nrColumns="3"
        textLeftStyle="body-1"
        textLeftTitle="Erfahrung mit echten, digitalen Prototypen sammeln"
        listLeft={true}
        textLeft={
          <>
            <li>
              Du arbeitest und lernst an einer echten Herausforderung und an
              einem echten Innovations-Projekt.
            </li>
            <li>
              Dir wird vielerlei Wissen vermittelt, welches Du direkt an deinem
              Projekt anwendest.
            </li>
            <li>Du experimentierst in einer geschützten Umgebung.</li>
          </>
        }
        listMiddle={true}
        textMiddleTitle="Intensive Teamerfahrung"
        textMiddle={
          <>
            <li>
              Du arbeitest in einem passionierten und autonomen Projekt-Team.
            </li>
            <li>Du arbeitest von Beginn an agil und eigenverantwortlich.</li>
            <li>
            Mentoren, Coaches und Expertinnen stehen Dir auf der Reise zu einem funktionierenden Team jederzeit bei.
            </li>
          </>
        }

        listRight={true}
        textRightTitle="Reichhaltiger Methodenschatz"
        textRight={
          <>
            <li>
            Design Thinking Methoden helfen, Dir schnell ein solides Verständnis für die Nutzer, deren Probleme und Bedürfnisse aufzubauen. Das Wissen ist die Grundlage für erfolgreiche Ideen.
            </li>
            <li>
              Agiles Arbeiten bleibt keine Phrase mehr. Arbeite in kurzen
              Iterationen mit klarem Fokus und reagiere flexibel auf die
              jüngsten Erkenntnisse.
            </li>
            <li>
            Schlanke Produktentwicklung setzt voraus, dass man sich Risiken bewusst stellt und über gezielte Experimente Erkenntnisse gewinnt. So lernt man, wie man echten Mehrwert für die Nutzerinnen und Nutzer generieren kann.
            </li>
          </>
        }
      />
    </section>
  );
}
