import React from "react";
import BlueHero from "../../shared/bluehero";
import hero from "../../../assets/img/components/hero/blueHeroProgram.webp";

export default function Hero() {
  return (
    <section id="blueHero">
      <BlueHero
        image={hero}
        lineStyle="blue-hero-text-right display-1 p-standard tt-uppercase t-right"
        line={"Was du\n mitnehmen \n wirst"}
      />
    </section>
  );
}
