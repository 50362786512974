import React from "react";
import DefautLayout from "../layouts/default";
import * as ProgramPage from "../components/screens/program";
import "../assets/sass/index.scss";

export default function Program() {
  return (
    <DefautLayout title="Programm">
      <div id="program">
        <ProgramPage.Header />
        <ProgramPage.Roles />
        <ProgramPage.Break />
        <ProgramPage.Facts />
        <ProgramPage.Hero />
        <ProgramPage.Benefits />
        <ProgramPage.Infograph />
        <ProgramPage.Values />
        <ProgramPage.LocalHeader />
        <ProgramPage.Process />
      </div>
    </DefautLayout>
  );
}
