import React from "react";
import VerticalTile from "../../shared/verticalTile";
import {
  Calendar,
  Agile,
  Campus,
  Money,
  Network,
  Student,
} from "../../../assets/img/icons";

let facts = [
  {
    title: "3 Monate Vollzeit (05.08. - 31.10.2024)",
    component: Calendar,
    alt: "kalender",
    content:
      "In einem agilen Team wirst Du in kurzen Iterationen arbeiten. Fortschritt passiert jeden Tag. Damit Du und das Team das Meiste aus der Zeit mitnehmen könnt, bist Du vollzeit verfügbar und im Einsatz.",
  },
  {
    title: "Ca. 1.100 EUR pro Monat",
    component: Money,
    alt: "geld",
    content:
      "Alle Teilnehmerinnen und Teilnehmer bekommen einen Zuschuss in Höhe von bis zu 1.100 EUR pro Monat.",
  },
  {
    title: "Erweitere dein Team",
    component: Network,
    alt: "netzwerk",
    content:
      "Im Projekt wirst Du eng mit Mitarbeitern der Ministerien und nachgelagerter Behörden zusammenarbeiten. Ihr werdet voneinander lernen und Du wirst zur Inspirationsquelle und Mitgestalter einer modernen Verwaltung.",
  },
  {
    title: "Agile & Lean",
    component: Agile,
    alt: "agile",
    content:
      "Erfahre ein intensives Training in agilen Arbeitsweisen und Design Thinking Methoden. Lerne Nutzerprobleme zu evaluieren und deine Lösung zu testen und weiter zu iterieren.",
  },
  {
    title: "Erfahrungen erweitern",
    component: Student,
    alt: "erfahrungen",
    content:
      "Verbessere deine Softskills, während Du intensiv in einem diversen Team arbeitest. Interne und externe Experten vermitteln ihr Wissen in zahlreichen Coachings und Workshops.",
  },
  {
    title: "Innovatives Umfeld",
    component: Campus,
    alt: "campus",
    content:
      "Arbeite gemeinsam mit Gleichgesinnten und lerne von unseren Expertinnen und Experten. Lasse dich von deinem Umfeld inspirieren und tauche ab in eine Welt voller Möglichkeiten.",
  },
];

export default function Facts() {
  return (
    <section  id="facts">
      <div className="grid p-grid-standard grid-gap-0 content">
        {facts.map((fact, index) => (
          <div className="col-12 md:col-6 xl:col-4" key={index}>
            <VerticalTile
              component={<img width="80" height="80" src={fact.component} alt={fact.alt} />}
              title={fact.title}
              excerpt={{
                content: fact.content,
              }}
            />
          </div>
        ))}
      </div>
    </section>
  );
}
