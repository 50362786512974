import React from "react";
import VerticalTile from "../../shared/verticalTile";
import pm from "../../../assets/img/components/roles/pm.webp";
import se from "../../../assets/img/components/roles/se.webp";
import ixd from "../../../assets/img/components/roles/ixd.webp";
import ImageButton from "../../shared/imageButton";

let roles = [
  {
    title: "Produktmanagerin (m/w/d) (die betriebswirtschaftliche Perspektive):",
    description:
      "Als Produktmanager in deinem Team bist Du dafür verantwortlich, den Prototypen, der das erkannte Problem aus der Verwaltung löst, zu definieren. Du bist Koordinator und Kommunikator im Team, bist für die Entscheidungsfindung zuständig und hast die Zeit und Stakeholder im Blick.",
    image: pm,
    buttonGridTemplateAreas: `'. . .''. icon text'`,
    buttontext: "Mitmachen!",
    buttonTextBackgroundColor: "black",
    buttonTextColor: "white",
    buttonIconColor: "blue",
    link: "/program/#breakProgram",
  },
  {
    title: "Designerin (m/w/d) (die User Experience Perspektive):",
    description:
      "Als Designer hilfst Du deinem Team zu verstehen, wie Benutzende und Technologie miteinander interagieren. Du bist zuständig für eine nutzerzentrierte Gestaltung der digitalen Lösung und lässt dein Wissen bezüglich einer ansprechenden Gestaltung der Schnittstelle zwischen Lösung und Nutzenden einfließen, um ein möglichst positives Nutzungserlebnis zu gewährleisten.",
    image: ixd,
    buttonGridTemplateAreas: `'. . .''. icon text'`,
    buttontext: "Mitmachen!",
    buttonTextBackgroundColor: "yellow",
    buttonTextColor: "black",
    buttonIconColor: "black",
    link: "/program/#breakProgram",
  },
  {
    title: "Softwareentwicklerin (m/w/d) (die technische Perspektive):",
    description:
      "Als Softwareentwickler erweckst Du die digitale Lösung durch Programmieren zum Leben. Du unterstützt Dein Team, indem Du euren digitalen Prototyp entwickelst und darauf achtest, dass das System später benutzbar, sicher und skalierbar ist. Du bist der „Macher” in eurem Team und setzt eure Idee technisch um. Dabei geht es nicht nur, um die bloße Entwicklung des Prototyps, sondern vor allem darum die technische Umsetzbarkeit einzuschätzen und den Markt aus einer technischen Perspektive zu beobachten.",
    image: se,
    buttonGridTemplateAreas: `'. . .''. icon text'`,
    buttontext: "Mitmachen!",
    buttonTextBackgroundColor: "blue",
    buttonTextColor: "white",
    buttonIconColor: "yellow",
    link: "/program/#breakProgram",
  },
];
export default function Roles() {
  return (
    <section  className="roles ic-40 xl:ic-60 content">
      {/* <p className="headline-2 px-standard">
      Wen wir suchen<br />
      </p> */}
      <div class="grid xs:grid-gap-20 md:grid-gap-50 lg:grid-gap-100 local-header pxt-grid-standard content ic-40 xl:ic-80">
        <div class="local-header--text col-12 lg:col-7 no-padding-bottom">
          <div class="tc-blue tt-uppercase fw-bolder m-0 display-1">
            <div class="count">
              <p>Wen wir suchen</p>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-gap-0 p-grid-standard">
        {roles.map((role, index) => (
          <div key={index} className="col-12 md:col-6 xl:col-4">
            <VerticalTile
              component={
                <ImageButton
                  image={role.image}
                  buttonGridTemplateAreas={role.buttonGridTemplateAreas}
                  buttontext={role.buttontext}
                  buttonTextBackgroundColor={role.buttonTextBackgroundColor}
                  buttonTextColor={role.buttonTextColor}
                  buttonIconColor={role.buttonIconColor}
                  link={role.link}
                  target={role.target}
                />
              }
              title={role.title}
              subTitle={role.description}
            />
          </div>
        ))}
      </div>
    </section>
  );
}
