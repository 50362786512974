import React, { useState, useCallback } from "react";
import discovery from "../../../assets/img/components/infograph/discovery.svg";
import { Controlled as ControlledZoom } from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { Zoom } from "../../../assets/img/icons";

export default function Infograph() {
  const [isZoomed, setIsZoomed] = useState(false);

  const handleImgLoad = useCallback(() => {
    setIsZoomed(true);
  }, []);

  const handleZoomChange = useCallback((shouldZoom) => {
    setIsZoomed(shouldZoom);
  }, []);

  const wrapperStyle = {
    width: "100%",
    height: "100%",
  };
  return (
    <section  id="infopragh">
      <div className="content py-standard">
        <div className="infograph">
          <div className="pxt-standard">
            <p className="headline-1">In den drei Monaten</p>
            <p className="body-1">von der Ideenfindung zum Launch</p>
            <img
              className="infograph-icon lg:hidden xl:hidden"
              src={Zoom}
              onClick={handleImgLoad}
              alt="zoom"
              role="presentation"
            />
            <ControlledZoom isZoomed={isZoomed} onZoomChange={handleZoomChange}>
              <img style={wrapperStyle} src={discovery} alt="Infografik, welche den Prozess von der Ideenfindung bis zum Launch illustriert" />
            </ControlledZoom>
          </div>
          <div className="grid body-2 p-grid-standard xs:grid-gap-20 md:grid-gap-50 lg:grid-gap-100">
            <div className="col-12 md:col-4">
              Das Team erlernt und benutzt bewährte Methoden, die dabei helfen,
              unbekannte Probleme der Zielgruppe aufzudecken und riskante
              Annahmen zu wiederlegen.
            </div>
            <div className="col-12 md:col-4">
              Das Team profitiert von seiner Diversität und entwickelt innovative
              Produktideen. Über methodisches Vorgehen und verschiedene
              Experimente werden die besten Ansätze ermittelt.
            </div>
            <div className="col-12 md:col-4">
            Die Erkenntnisse aus den durchgeführten Experimente weisen den Weg zum Bau eines Produkts mit echten Mehrwert für die Nutzerinnen.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
