import React from "react";

export default function HorizontalText(props) {
  return (
    <section  className="horizontal-tile">
      <div className="horizontal-tile--divider--bottom">
        <p className="body-1 pt-30 pb-30">{props.text}</p>
      </div>
    </section>
  );
}
