import React from "react";
import { useEffect } from "react"


export default function Break() {
  useEffect(() => {
   /*  const script = document.createElement('script');
  
    script.src = "https://mps-files.de/byte/js/iframe.js";
    script.async = true;
  
    document.body.appendChild(script);
    
    return () => {
      document.body.removeChild(script);
    } */
  }, []);
  return (
    
    <section id="breakProgram">
        <div className="grid p-grid-standard xs:grid-gap-20 md:grid-gap-50 lg:grid-gap-100 content no-padding-top">
            <div className="col-12 body-1">
              <div className="note bc-lightgrey p-30 lg:p-50">
                <p>
                Nach 4 erfolgreichen Batches mit zahlreichen Projekten aus der Bayerischen Verwaltung geht die Digitalschmiede Bayern in die nächste Runde. Wir haben die Zeit genutzt, um das Programm auf Basis unserer Erfahrungen aus den  bisherigen Batches zu optimieren und anzupassen.
                Los geht es am 05. August 2024 und endet nach ca. drei aufregenden Monaten am 31. Oktober.
                </p>
            </div>
            </div>
         
        </div>     

    </section>
    
  );
}