import React from "react";
import HorizontalText from "../../shared/horizontalText";

export default function Values() {
  return (
    <section  id="values">
      <div className="grid p-grid-standard xs:grid-gap-20 md:grid-gap-50 lg:grid-gap-100 content">
        <div className="col-12 lg:col-6 body-1">
          In den Phasen setzen wir auf bewährte Denkschulen: <br />
          <strong>
            Design Thinking, Agiles Arbeiten und Lean Product Development.
          </strong>
          <br />
          <br /> Zeitgleich halten wir an unseren Kern-Werten fest:
          <br />
        </div>
        <div className="col-12 lg:col-6 body-1 ">
          <HorizontalText
            text={
              <>
                <strong>Divers:</strong> Wir arbeiten in interdisziplinären und diversen
                Projekt-Teams.
              </>
            }
          />
          <HorizontalText
            text={
              <>
                <strong>Der Mensch im Mittelpunkt: </strong>Wir bauen digitale Prototypen, die Bürgerinnen und Bürger wirklich lieben.
              </>
            }
          />
          <HorizontalText
            text={
              <>
                <strong>Schlank:</strong> Wir bewegen uns schnell und versuchen mit
                kleinen Experimenten viel Wissen zu generieren.
              </>
            }
          />
          <HorizontalText
            text={
              <>
                <strong>Wirkung:</strong> Wir arbeiten an echten Herausforderungen für das
                Wohl aller Bürger und Bürgerinnen.
              </>
            }
          />
          <HorizontalText
            text={
              <>
                <span lang="en"><strong>Learning-by-doing:</strong></span> Wir sammeln Erfahrung bei der
                Umsetzung und Realisierung von Ideen.
              </>
            }
          />
        </div>
      </div>
    </section>
  );
}
