import React from "react";
import application_image from "../../../assets/img/components/header/IMG_2448.jpg";
import ApplicationProcess from "../../shared/applicationProcess";

export default function Process() {
  return (
    <section id="process">
      <ApplicationProcess
        steps={[
          "Bewerbung einreichen",
          "Virtuelles Kennenlernen",
          "Persönliches Kennenlernen & lösen einer Herausforderung",
          "Themenzuweisung",
          "Den Aufenthalt planen",
          "In der Digitalschmiede durchstarten",
        ]}
        title="So wirst du Teil
        der Digitalschmiede"
        buttonIconColor="yellow"
        buttontext="Jetzt als Fellow bewerben"
        buttonGridTemplateAreas={`'vred . .''. icon text'`}
        buttonTextBackgroundColor="black"
        buttonTextColor="white"
        image={application_image}
        link="/program/#breakProgram"
      />
    </section>
  );
}
